.contact__option[data-v-9c526e7a] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}
.contact__option .contact__option__image[data-v-9c526e7a] {
    width: 48px;
    height: 48px;
    min-width: 48px;
    background-repeat: no-repeat;
    background-size: 48px;
    padding-right: 1rem;
}
.contact__option h3[data-v-9c526e7a],
.contact__option p[data-v-9c526e7a],
.contact__option a[data-v-9c526e7a] {
    color: var(--lightTextColor);
}
.contact__option h3[data-v-9c526e7a] {
    font-size: 1.2rem;
}
@media (max-width: 768px) {
.contact__option .contact__option__image[data-v-9c526e7a] {
        width: 42px;
        height: 42px;
        min-width: 42px;
        background-size: 42px;
}
}
@media (max-width: 340px) {
.contact__option .contact__option__image[data-v-9c526e7a] {
        width: 32px;
        height: 32px;
        min-width: 32px;
        background-size: 32px;
        padding-right: 0.5;
}
}