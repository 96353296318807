.block__wrapper[data-v-e144d2be] {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2rem;
    gap: 4rem;
    z-index: 2;
    background: var(--backgroundColor);
    flex: 1 1;
}

/*  Services block  */
.services__block[data-v-e144d2be] {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}
.services__wrapper > #title[data-v-e144d2be] {grid-area: title;}
.services__wrapper > #cards[data-v-e144d2be] {grid-area: cards;}
.services__wrapper > #links[data-v-e144d2be] {grid-area: links;}
.services__wrapper[data-v-e144d2be] {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas: 
    "title title title title title"
    "cards cards cards cards links";
    grid-template-columns: 1fr 180px;
    grid-gap: 2rem;
    gap: 2rem;
}
.services__cards[data-v-e144d2be] {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.services__links[data-v-e144d2be] {
    /* display: grid; */
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    gap: 1rem;
    width: 180px;
    /* height: 0; */
}
@media (max-width: 768px) {
.services__wrapper[data-v-e144d2be] {
        grid-template-areas: 
        "links"
        "title"
        "cards";
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
}
.services__links[data-v-e144d2be] {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        height: 100%;
        width: 100%;
}
}
@media (max-width: 360px) {
.services__links[data-v-e144d2be] {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
}
}