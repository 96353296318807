@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Raleway:wght@400;500;600;700&family=Unbounded:wght@600&display=swap');

:root {
  --darkBlue: #16122B;
  --darkBlue2: #311c9c;
  --hoverBlue: #141024;
  --gold: #D4A549;
  --hoverGold: #D6AB56;
  --grey: #898989;
  --backgroundColor: #F7F7F7;
  --lightTextColor: #FFFFFF;
  --darkTextColor: var(--darkBlue);
  --shadow: 0 0.03em 0.3em rgb(0 0 0 / 20%);
}

* {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  transition: all ease-in-out 0.2s;
}

/* ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  z-index: 0;
  background: transparent;
}

::-webkit-scrollbar {
  z-index: 0;
  width: 6px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--gold);
} */

html,
body {
  min-height: 100%;
  overflow-x: hidden;
  background: #F7F7F7;
  background: var(--backgroundColor);
  scroll-behavior: smooth;
}

body {
  height: 100vh;
}

a {
  cursor: pointer;
  text-decoration: underline;
}

h1 {
  font-size: 4.5rem;
  font-family: 'Raleway', sans-serif;
}

h2 {
  font-size: 3rem;
  font-family: 'Raleway', sans-serif;
  color: #16122B;
  color: var(--darkBlue);
}

h3 {
  font-size: 2.2rem;
  font-family: 'Raleway', sans-serif;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

@media (max-width: 768px) {
  * {
    font-size: 14px;
  }

  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.6rem;
  }
}

@media (max-width: 520px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 1.6rem;
  }
}

/* -=-=-=- Toast setting -=-=-=- */
.toast-block {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 2rem;
  height: 100vh;
}

.p-toast {
  width: 350px !important;
  opacity: 1 !important;
}

.p-toast-message {
  width: 350px !important;
  padding: 1.5rem 2rem !important;
  background-color: #D4A549 !important;
  background-color: var(--gold) !important;
  border-radius: 0.1rem !important;
  color: #F7F7F7 !important;
  color: var(--backgroundColor) !important;
  box-shadow: 0 0.03em 0.3em rgb(0 0 0 / 20%) !important;
  box-shadow: var(--shadow) !important;
  border: none !important;
  opacity: 1 !important;
  margin-top: 1rem;
}

.p-toast-message-icon {
  display: none !important;
}

.p-toast-message * {
  color: #F7F7F7 !important;
  color: var(--backgroundColor) !important;
  transition: none !important;
}

.p-toast-summary {
  font-weight: 700 !important;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 0 !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 !important;
}

.p-toast-message-error {
  background-color: #fd4141 !important;
  box-shadow: 0 0.03em 0.3em rgb(0 0 0 / 20%) !important;
  box-shadow: var(--shadow) !important;
}

.p-toast-message-exit-active {
  opacity: 0 !important;
  max-height: 0;
  margin-bottom: 0;
  overflow: hidden;
  transition: max-height .45s cubic-bezier(0,1,0,1),opacity .3s,margin-bottom .3s;
}

.p-toast-message-exit-done {
  opacity: 0 !important;
  transition: none !important;
}

.p-toast-message-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: opacity .3s,-webkit-transform .3s !important;
  transition: transform .3s,opacity .3s !important;
  transition: transform .3s,opacity .3s,-webkit-transform .3s !important;
}

.p-toast-message-enter-done {
  opacity: 1 !important;
  transition: none !important;
}
