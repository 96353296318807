.link[data-v-1823fe2e] {
    text-decoration: none;
    color: var(--lightTextColor);
}
li[data-v-1823fe2e] {
    list-style: none;
}
.header[data-v-1823fe2e] {
    position: relative;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background: var(--darkBlue);
    color: var(--lightTextColor);
}
.header__wrapper[data-v-1823fe2e] {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.header__logo[data-v-1823fe2e] {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: var(--lightTextColor);
}
.logo__image[data-v-1823fe2e] {
    width: 64px;
    height: 64px;
}
.logo__name[data-v-1823fe2e] {
    font-weight: 600;
    font-size: 1.2rem;
    margin-left: 0.5rem;
}
.header__nav[data-v-1823fe2e] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    font-size: 1rem;
    text-align: center;
}

/* a:hover {
    color: var(--gold);
} */

/*  Burger  */
.burger__menu__container[data-v-1823fe2e] {
    opacity: 0;
    display: none;
}
.burger__menu__container .burger__menu[data-v-1823fe2e] {
    width: 36px;
    height: 3px;
    background-color: var(--lightTextColor);
    transition: all ease-in-out 0.2s;
}
.burger__content[data-v-1823fe2e] {
    display: none;
    opacity: 0;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: var(--hoverBlue);
}
.header__burger__nav[data-v-1823fe2e] {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 2px;
}
.header__burger__nav li[data-v-1823fe2e] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    height: 100%;
    width: 100%;
    background-color: var(--darkBlue);
}
.header__burger__nav li[data-v-1823fe2e]:hover {
    background-color: var(--hoverBlue);
}
.header__burger__nav li .link[data-v-1823fe2e] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
}
.header__wrapper .link[data-v-1823fe2e] {
    display: flex;
    flex-direction: row;
    align-items: center;
}
@media (max-width: 768px) {
.logo__name[data-v-1823fe2e] {
        font-size: 1.2rem;
}
.logo__image[data-v-1823fe2e] {
        width: 48px;
        height: 48px;
}
.header__nav[data-v-1823fe2e] {
        display: none;
}
.burger__menu__container[data-v-1823fe2e] {
        opacity: 1;
        height: 100%;
        width: 50px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
}
.burger__content[data-v-1823fe2e] {
        display: flex;
        opacity: 1;
}
.header.active .burger__menu__1[data-v-1823fe2e] {
        -webkit-transform: translateY(5.5px) rotate(45deg);
                transform: translateY(5.5px) rotate(45deg);
}
.header.active .burger__menu__2[data-v-1823fe2e] {
        -webkit-transform: translateY(-5.5px) rotate(-45deg);
                transform: translateY(-5.5px) rotate(-45deg);
}
.header.active[data-v-1823fe2e] {
        height: 180px;
}
}