.container[data-v-404f2508] {
    max-width: 1200px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (max-width: 1200px) {
.container[data-v-404f2508] {
        width: calc(100% - 2rem);
}
}