.button__primary[data-v-c3f3c185] {
    width: 180px;
    height: 64px;
    border: none;
    background: var(--gold);
    color: var(--lightTextColor);
    font-size: 1rem;
    padding: 0;
    box-shadow: var(--shadow);
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button__primary[data-v-c3f3c185]:hover {
    background: var(--hoverGold);
    scale: 1.02;
}