.contacts__block[data-v-2449a73c] {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contacts__wrapper[data-v-2449a73c] {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 0;
}
.contacts__wrapper .contacts__card[data-v-2449a73c] {
    width: 100%;
    height: 520px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--darkBlue);
    color: var(--lightTextColor);
}
.contacts__info[data-v-2449a73c],
.contacts__map[data-v-2449a73c] {
    width: 50%;
    height: 100%;
}
.contacts__map *[data-v-2449a73c] {
    width: 100% !important;
    height: 100% !important;
}
.contacts__info[data-v-2449a73c] {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.contacts__info *[data-v-2449a73c] {
    padding: 2rem;
    padding-right: 1rem;
}
.wrapper[data-v-2449a73c] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
@media (max-width: 768px) {
.contacts__wrapper .contacts__card[data-v-2449a73c] {
        flex-direction: column;
        height: 730px;
}
.contacts__info[data-v-2449a73c],
    .contacts__map[data-v-2449a73c] {
        width: 100%;
        height: 50%;
}
.wrapper[data-v-2449a73c] {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5rem;
        gap: 0.5rem
}
}
@media (max-width: 520px) {
.contacts__wrapper .contacts__card[data-v-2449a73c] {
        flex-direction: column;
        height: 940px;
}
.wrapper[data-v-2449a73c] {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fill, minmax(60px, 1fr));
        gap: 2rem;
        padding-right: 2rem;
}
.contacts__info[data-v-2449a73c],
    .contacts__map[data-v-2449a73c] {
        width: 100%;
        height: auto;
        flex: 1 1;
}
}
@media (max-width: 340px) {
.contacts__info *[data-v-2449a73c] {
        padding: 1rem;
}
.contacts__wrapper .contacts__card[data-v-2449a73c] {
        height: 840px;
        gap: 1rem;
}
}