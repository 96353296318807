table[data-v-6fe4ea95] {
    display: inline;
    width: 100% !important;
    overflow-x: auto !important;
}
colgroup[data-v-6fe4ea95] {
	width: 100% !important;
}
td[data-v-6fe4ea95], tr[data-v-6fe4ea95], th[data-v-6fe4ea95] {
    text-align: center !important;
    font-size: 12px !important;
    background-color: transparent !important;
    border: 1px solid var(--grey);
    height: auto !important;
    vertical-align: middle !important;
}
.top[data-v-6fe4ea95] {
    vertical-align: top !important;
}
td[data-v-6fe4ea95] {
    padding: 0 0.5rem;
    box-sizing: border-box;
}
ol[data-v-6fe4ea95] {
    list-style: decimal !important;
    list-style-type: decimal !important;
            padding-left: 3rem !important;
}
ol .Normal[data-v-6fe4ea95] {
    text-align: start;
}
span.a[data-v-6fe4ea95] { font-family:Tahoma; font-size:8pt; text-decoration:none; color:#000000
}
.awlist1[data-v-6fe4ea95] { list-style:none; counter-reset:awlistcounter93_0 6499
}
.awlist1 > li[data-v-6fe4ea95]:before { content:counter(awlistcounter93_0); counter-increment:awlistcounter93_0
}
.awlist2[data-v-6fe4ea95] { list-style:none; counter-reset:awlistcounter94_0 6499
}
.awlist2 > li[data-v-6fe4ea95]:before { content:counter(awlistcounter94_0); counter-increment:awlistcounter94_0
}
.awlist3[data-v-6fe4ea95] { list-style:none; counter-reset:awlistcounter95_0 6499
}
.awlist3 > li[data-v-6fe4ea95]:before { content:counter(awlistcounter95_0); counter-increment:awlistcounter95_0
}
.awlist4[data-v-6fe4ea95] { list-style:none; counter-reset:awlistcounter96_0 8212
}
.awlist4 > li[data-v-6fe4ea95]:before { content:counter(awlistcounter96_0); counter-increment:awlistcounter96_0
}
.awlist5[data-v-6fe4ea95] { list-style:none; counter-reset:awlistcounter97_0 1799
}
.awlist5 > li[data-v-6fe4ea95]:before { content:counter(awlistcounter97_0); counter-increment:awlistcounter97_0
}