.block__wrapper[data-v-059f60e7] {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 4rem;
    z-index: 2;
    background: var(--backgroundColor);
    flex: 1 1;
	padding-top: 2rem;
}
.tariffs__block[data-v-059f60e7] {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tariffs__wrapper[data-v-059f60e7] {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 0;
}
