.service__detail[data-v-7e65e202] {
    background: #FFFFFF;
    color: var(--darkBlue);
    box-shadow: var(--shadow);
    border-radius: 4px;
}
.service__detail summary[data-v-7e65e202] {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    list-style-type: none;
}
.service__detail summary[data-v-7e65e202],
.service__detail .details__info[data-v-7e65e202] {
    padding: 0.8rem;
}
.service__detail summary .icon[data-v-7e65e202] {
    display: inline-block;
    margin-left: auto;
    width: 32px;
    height: 32px;
    transition: all ease-in-out 0.2s;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    color: var(--darkBlue);
}
.service__detail[open] summary .icon[data-v-7e65e202] {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
h3[data-v-7e65e202] {
    font-size: 1.5rem;
    color: var(--darkBlue);
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
    margin-right: 1rem;
    font-weight: 600;
    text-align: start !important;
}
.detail__info[data-v-7e65e202] {
    padding: 0 3.5rem 1rem 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: justify;
}
.__primary[data-v-7e65e202] {
    color: var(--gold);
    font-weight: 600;
    text-align: start;
}
.docs__list[data-v-7e65e202] {
    text-align: start;
}
.info_description[data-v-7e65e202],
.additional__info[data-v-7e65e202] {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
details[open] summary~*[data-v-7e65e202] {
    -webkit-animation: sweep-data-v-7e65e202 .2s ease-in-out;
            animation: sweep-data-v-7e65e202 .2s ease-in-out;
}
.detail__wrapper[data-v-7e65e202] {
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.docs__block[data-v-7e65e202] {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.price__list[data-v-7e65e202] {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.position[data-v-7e65e202] {
    background: #FFFFFF;
    color: var(--darkBlue);
    box-shadow: var(--shadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 4px;
}
.hide[data-v-7e65e202], svg[data-v-7e65e202] {
    opacity: 0;
    display: none;
}
@-webkit-keyframes sweep-data-v-7e65e202 {
0% {
        opacity: 0;
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px)
}
100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0)
}
}
@keyframes sweep-data-v-7e65e202 {
0% {
        opacity: 0;
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px)
}
100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0)
}
}
@media (max-width: 520px) {
h3[data-v-7e65e202] {
        font-size: 1.4rem;
}
}