.service__link[data-v-66d66a23] {
    width: 100%;
    height: 180px;
    max-width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    text-decoration: none;
    margin: auto;
    margin-top: 0;
}
.service__image[data-v-66d66a23] {
    width: 92px;
    height: 92px;
    -webkit-user-select: none;
    user-select: none;
    background: no-repeat center;
}
h3[data-v-66d66a23] {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--darkBlue);
    transition: all ease-in-out 0.2s;
    -webkit-user-select: none;
            user-select: none;
}
.active h3[data-v-66d66a23] {
    color: var(--gold);
}
.service__link[data-v-66d66a23]:hover {
    /* box-shadow: var(--shadow); */
    scale: 1.02;
}
@media (max-width: 768px) {
.service__link[data-v-66d66a23] {
        width: 100%;
        height: 120px;
        max-width: 120px;
}
.service__image[data-v-66d66a23] {
        width: 72px;
        height: 72px;
}
}
@media (max-width: 520px) {
.service__link[data-v-66d66a23] {
        width: 100%;
        height: 100%;
        max-width: 96px;
}
.service__image[data-v-66d66a23] {
        width: 48px;
        height: 48px;
}
h3[data-v-66d66a23] {
        text-align: center;
        font-size: 1.2rem;
}
}
