h3[data-v-934d31e5] {
    font-size: 1.5rem;
}
.footer[data-v-934d31e5] {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--darkBlue);
    margin-top: 6rem;
}
.footer__wrapper > #title1[data-v-934d31e5] {grid-area: title1;}
.footer__wrapper > #title2[data-v-934d31e5] {grid-area: title2;}
.footer__wrapper > #title3[data-v-934d31e5] {grid-area: title3;}
.footer__wrapper > #separator1[data-v-934d31e5] {grid-area: separator1;}
.footer__wrapper > #separator2[data-v-934d31e5] {grid-area: separator2;}
.footer__wrapper > #separator3[data-v-934d31e5] {grid-area: separator3;}
.footer__wrapper > #info1[data-v-934d31e5] {grid-area: info1;}
.footer__wrapper > #info2[data-v-934d31e5] {grid-area: info2;}
.footer__wrapper > #info3[data-v-934d31e5] {grid-area: info3;}
.footer__wrapper[data-v-934d31e5] {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas: 
    "title1 title2 title3"
    "separator1 separator2 separator3"
    "info1 info2 info3";
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem 0;
    color: var(--lightTextColor);
}
.footer__wrapper .footer__separator[data-v-934d31e5] {
    width: 100%;
    height: 1px;
    background: var(--lightTextColor);
}
.footer__wrapper .footer__info ul[data-v-934d31e5] {
    margin-left: 0.5rem;
}
.footer__wrapper .footer__info li[data-v-934d31e5] {
    list-style-type: "-";
    /* text-indent: 0.5rem; */
}
.footer__wrapper .footer__info li span a[data-v-934d31e5] {
    color: var(--gold);
}
.footer__wrapper .footer__info .ul__icon[data-v-934d31e5] {
    margin-left: 0 !important;
}
.info__img[data-v-934d31e5] {
    text-indent: 0 !important;
    list-style-type: none !important;
    margin-bottom: 1rem;
    line-height: 20px;
    display: flex;
}
.img__1[data-v-934d31e5] {
    margin: auto;
    height: 20px;
    width: 20px;
    background-size: 20px;
    background: url("/public/icons/phone.svg") no-repeat;
}
.img__2[data-v-934d31e5] {
    margin: auto;
    height: 20px;
    width: 20px;
    background-size: 20px;
    background: url("/public/icons/address.svg") no-repeat;
}
.img__3[data-v-934d31e5] {
    margin: auto;
    height: 20px;
    width: 20px;
    background-size: 20px;
    background: url("/public/icons/web.svg") no-repeat;
}
.info__img span[data-v-934d31e5] {
    width: 100%;
    margin-left: 0.5rem;
}
@media (max-width: 520px) {
.footer__wrapper[data-v-934d31e5] {
        grid-template-areas: 
        "title1"
        "separator1"
        "info1"
        "title2"
        "separator2"
        "info2"
        "title3"
        "separator3"
        "info3";
        grid-template-columns: 1fr;
}
}