
/*  Common settings  */
.__primary[data-v-6c54a814] {
    font-size: inherit;
    font-family: inherit;
    color: var(--gold);
}
.tinting[data-v-6c54a814] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}
.block__wrapper[data-v-6c54a814] {
    width: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 6rem;
}

/*  Greeting__block settings  */
.greeting__block[data-v-6c54a814] {
    position: relative;
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url("./greeting_back.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.greeting__block .greeting__wrapper[data-v-6c54a814] {
    z-index: 2;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    text-align: center;
    align-items: center;
    gap: 1.5rem;
    margin: auto;
    color: var(--lightTextColor);
}
.greeting__block .greeting__wrapper h1[data-v-6c54a814],
.greeting__block .greeting__wrapper h3[data-v-6c54a814] {
    line-height: 1.2;
}

/*  About__block settings  */
.about__us__block[data-v-6c54a814] {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.about__us__block .about__us__wrapper[data-v-6c54a814] {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
/*    padding: 6rem 0;*/
}
.about__us__block .about__us__wrapper .text[data-v-6c54a814] {
    color: var(--darkBlue);
    text-align: justify;
}
.about__us__block .about__us__wrapper .about__us__cards[data-v-6c54a814] {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.about__us__block .about__us__wrapper .card[data-v-6c54a814] {
    position: relative;
    width: calc(50% - 1rem);
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: var(--shadow);
    transition: all ease-in-out 0.2s;
}
.about__us__block .about__us__wrapper .card[data-v-6c54a814]:hover {
    scale: 1.02;
}
.about__us__block .about__us__wrapper .text__wrapper[data-v-6c54a814] {
    position: relative;
    z-index: 2;
    max-width: 410px;
    min-height: 45%;
    text-align: center;
    color: var(--lightTextColor);
    margin-bottom: 1rem;
}
.notary[data-v-6c54a814] {
    background-image: url("./notary.jpg");
    background-position-y: 3%;
}
.office[data-v-6c54a814] {
    background-image: url("./office.jpg");
    background-position-y: 100%;
}

/*  Service__block settings  */
.service__block[data-v-6c54a814] {
    width: 100%;
    height: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--darkBlue);
    color: var(--lightTextColor);
/*    margin: 6rem 0;*/
}
.service__block h2[data-v-6c54a814] {
    color: var(--lightTextColor);
}
.service__block .service__wrapper[data-v-6c54a814] {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}
.service__block .service__wrapper .service__cards__wrapper[data-v-6c54a814] {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 2rem;
    padding: 4rem 0;
}
@media (max-width: 768px) {
.service__block .service__wrapper .service__cards__wrapper[data-v-6c54a814] {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
}
@media (max-width: 520px) {
.about__us__block .about__us__wrapper .about__us__cards[data-v-6c54a814] {
        flex-direction: column;
        gap: 2rem;
}
.about__us__block .about__us__wrapper .card[data-v-6c54a814] {
        width: 100%;
        height: 400px;
}
.about__us__block .about__us__wrapper .text__wrapper[data-v-6c54a814] {
        max-width: 360px;
        min-height: 34%;
        text-align: center;
        color: var(--lightTextColor);
        margin-bottom: 1rem;
}
.service__block[data-v-6c54a814] {
        min-height: 360px;
}
.service__block h2[data-v-6c54a814] {
        text-align: center;
}
.service__block .service__wrapper .service__cards__wrapper[data-v-6c54a814] {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}
}
@media (max-width: 360px) {
.greeting__block .greeting__wrapper h1[data-v-6c54a814] {
        font-size: 2.5rem;
}
}