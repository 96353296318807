.service__card[data-v-8df6c838] {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
    text-decoration: none;
}
.service__image[data-v-8df6c838] {
    width: 120px;
    height: 120px;
    -webkit-user-select: none;
    user-select: none;
}
h3[data-v-8df6c838] {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--lightTextColor);
    transition: all ease-in-out 0.2s;
    font-weight: 700;
}
.service__card[data-v-8df6c838]:hover {
    background-color: var(--hoverBlue);
    box-shadow: var(--shadow);
    scale: 1.02;
}
.service__card:hover h3[data-v-8df6c838] {
    color: var(--gold);
}
@media (max-width: 768px) {
.service__card[data-v-8df6c838] {
        min-width: 200px;
}
.service__image[data-v-8df6c838] {
        width: 72px;
        height: 72px;
}
}
@media (max-width: 520px) {
h3[data-v-8df6c838] {
        font-size: 1.2rem;
}
.service__card[data-v-8df6c838] {
        min-width: 100px;
}
.service__image[data-v-8df6c838] {
        width: 64px;
        height: 64px;
}
}