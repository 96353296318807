.form__block[data-v-c1129542] {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.form__wrapper[data-v-c1129542] {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}
.form__wrapper .form[data-v-c1129542] {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.form  .form__data[data-v-c1129542] {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.form .form__item[data-v-c1129542] {
    position: relative;
    width: 100%;
}
.form .form__item .form__input[data-v-c1129542] {
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border: none;
    outline: none !important;
    padding: 1rem;
    padding-left: 3.5rem;
    height: 4rem;
}
.form .form__item .fas[data-v-c1129542] {
    position: absolute;
    top: 1.3rem;
    left: 1rem;
    width: 24px;
    height: 24px;
}
.form .form__question .form__item .form__input[data-v-c1129542] {
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    border: none;
    outline: none !important;
    padding: 1.3rem;
    padding-left: 3.5rem;
    height: 4rem;
}
.form  .form__question[data-v-c1129542] {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.form .form__question .form__item[data-v-c1129542] {
    height: 180px;
}
.form .form__question .form__item #form__quesion[data-v-c1129542] {
    outline: none !important;
    resize: none;
    height: 100%;
}
.button__wrapper[data-v-c1129542] {
    z-index: 1;
    position: relative;
}
.button__primary[data-v-c1129542] {
    z-index: 1;
    min-width: 64px;
    width: 64px;
    height: 180px;
    border: none;
    background: var(--gold);
    color: var(--lightTextColor);
    font-size: 1rem;
    padding: 0;
    box-shadow: var(--shadow);
    cursor: pointer;
    transition: all ease-in-out 0.2s;
}
.button__text[data-v-c1129542] {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: calc(50% - 0.7rem);
    left: -0.5rem;
    rotate: 90deg;
    color: var(--lightTextColor);
    -webkit-user-select: none;
            user-select: none;
}
.button__primary[data-v-c1129542]:hover {
    background: var(--hoverGold);
    scale: 1.02;
}
.button__text:hover .button__primary[data-v-c1129542] {
    background: var(--hoverGold);
}
.button__primary .button__primary[data-v-c1129542] {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    box-shadow: none;
    z-index: 3;
}
.user[data-v-c1129542] {
    background-image: url("/public/icons/name_grey.svg");
}
.phone[data-v-c1129542] {
    background-image: url("/public/icons/phone_grey.svg");
}
.mail[data-v-c1129542] {
    background-image: url("/public/icons/mail_grey.svg");
}
.message[data-v-c1129542] {
    background-image: url("/public/icons/message_grey.svg");
}
@media (max-width: 768px) {
.form  .form__data[data-v-c1129542] {
        flex-direction: column;
}
.button__text[data-v-c1129542] {
        z-index: 2;
        left: -0.3rem;
}
}
@media (max-width: 520px) {
.form  .form__question[data-v-c1129542] {
        flex-direction: column;
}
.button__primary[data-v-c1129542] {
        min-width: 180px;
        width: 180px;
        height: 64px;
}
.button__text[data-v-c1129542] {
        cursor: pointer;
        position: absolute;
        z-index: 2;
        top: calc(50% - 0.8rem);
        left: calc(90px - 36px);
        rotate: 0deg;
        color: var(--lightTextColor);
        -webkit-user-select: none;
                user-select: none;
}
}