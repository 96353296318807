.block__wrapper[data-v-bab3de96] {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2rem;
    gap: 4rem;
    z-index: 2;
    background: var(--backgroundColor);
    flex: 1 1;
}

/*  About block  */
.about__block[data-v-bab3de96] {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
}
.about__wrapper[data-v-bab3de96] {
    width: 100%;
    height: 100%;
    min-height: 480px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    flex-direction: row;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}
.about__wrapper .about__text[data-v-bab3de96] {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
}
.about__wrapper .about__text h2[data-v-bab3de96] {
    line-height: 1.2;
    margin-bottom: 2rem;
}
.about__wrapper .about__text p[data-v-bab3de96] {
    font-size: 1rem;
}
.about__wrapper .about__gallery[data-v-bab3de96] {
    height: 100%;
    display: grid;
    grid-template-areas: 
    "img1 img1 img1 img1 img1"
    "img1 img1 img1 img1 img1"
    "img2 img2 img3 img3 img4";
    grid-gap: 1rem;
    gap: 1rem;
}
.about__wrapper .about__gallery > #img1[data-v-bab3de96] {grid-area: img1;}
.about__wrapper .about__gallery > #img2[data-v-bab3de96] {grid-area: img2;}
.about__wrapper .about__gallery > #img3[data-v-bab3de96] {grid-area: img3;}
.about__wrapper .about__gallery > #img4[data-v-bab3de96] {grid-area: img4;}
.about__wrapper .about__gallery .gallery__img[data-v-bab3de96] {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: var(--shadow);
}
#img1[data-v-bab3de96] { background-image: url("./gallery/3.jpg")
}
#img2[data-v-bab3de96] { background-image: url("./gallery/2.jpg")
}
#img3[data-v-bab3de96] { background-image: url("./gallery/1.jpg")
}
#img4[data-v-bab3de96] { background-image: url("./gallery/4.jpg")
}

/*  Staff block  */
.staff__block[data-v-bab3de96] {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
}
.staff__wrapper[data-v-bab3de96] {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.staff__wrapper h2[data-v-bab3de96] {
    margin-top: 1rem;
}
.card__wrapper[data-v-bab3de96] {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    gap: 2rem;
}
.text__wrapper[data-v-bab3de96] {
    margin-top: 2rem;
}
.text__wrapper h3[data-v-bab3de96] {
    font-size: 1.6rem;
}
@media (max-width: 768px) {
.about__wrapper[data-v-bab3de96] {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 400px;
}
}
@media (max-width: 632px) {
.card__wrapper[data-v-bab3de96] {
        justify-content: center;
}
.staff__wrapper[data-v-bab3de96] {
        text-align: center;
}
}
@media (max-width: 360px) {
.about__wrapper[data-v-bab3de96] {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 300px;
}
}