.tinting[data-v-3ebdab51] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}
.stuff__card__wrapper[data-v-3ebdab51] {
    display: flex;
    flex-direction: row;
    gap: 4rem;
}
.stuff__card[data-v-3ebdab51] {
    position: relative;
    width: 280px;
    height: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: var(--shadow);
    transition: all ease-in-out 0.2s;
}
.stuff__card__text[data-v-3ebdab51] {
    position: relative;
    z-index: 2;
    max-width: 280px;
    min-height: 30%;
    text-align: start;
    color: var(--darkTextColor);
    margin-bottom: 1rem;
}
.stuff__card__text h3[data-v-3ebdab51] {
    font-size: 1.6rem;
}
@media (max-width: 650px) {
.stuff__card__wrapper[data-v-3ebdab51] {
        flex-direction: column;
}
}