.block__wrapper[data-v-28fffcae] {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 4rem;
    z-index: 2;
    background: var(--backgroundColor);
    flex: 1 1;
}
.container[data-v-28fffcae] {
	align-items: center;
	background-image: linear-gradient(to bottom right, var(--darkBlue), var(--darkBlue2));
	color: #fff;
	display: flex;
	flex-direction: column;
	font-family: 'Raleway', sans-serif;
	justify-content: center;
	flex: 1 1;
	text-align: center;
    margin: 0;
	overflow: hidden;
	position: relative;
}
.container h1[data-v-28fffcae] {
	font-size: 10em;
	margin: 0 0 0.5em;
	line-height: 10px;
}
.container p[data-v-28fffcae] {
	font-size: 1.2em;
	line-height: 26px;
}
.container small[data-v-28fffcae] {
	opacity: 0.7;
}
.container a[data-v-28fffcae] {
	color: #eee;
}
.circle[data-v-28fffcae] {
	background-image: linear-gradient(to top right, var(--darkBlue), var(--darkBlue2));
	/* border-radius: 50%; */
	position: absolute;
	z-index: 10;
}
.circle.small[data-v-28fffcae] {
	top: 200px;
	left: 150px;
	width: 100px;
	height: 100px;
}
.circle.medium[data-v-28fffcae] {
	background-image: linear-gradient(to bottom left, var(--darkBlue), var(--darkBlue2));
	bottom: -70px;
	left: 0;
	width: 200px;
	height: 200px;
}
.circle.big[data-v-28fffcae] {
	top: -100px;
	right: -50px;
	width: 400px;
	height: 400px;
}
@media screen and (max-width: 480px) {
.container h1[data-v-28fffcae] {
		font-size: 8em;
}
.container p[data-v-28fffcae] {
		font-size: 1em;
}
}