.block__wrapper[data-v-216e860c] {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 2rem;
    gap: 4rem;
    z-index: 2;
    background: var(--backgroundColor);
    flex: 1 1;
}
